.info_image {
  min-height: 12rem;
  width: 12rem;
  border-radius: 50%;
  background-image: url("../static/profile_images.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 50% 50%;
}

.info_p {
  margin-bottom: 0px;
}

.info_fullname {
  font-size: 1.5rem;
}
