.logoBar {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.logo {
    height: 2rem;
    width: 2rem;
}

@media only screen and (min-width: 768px) {
    .logo {
        height: 3.75rem;
        width: 3.75rem;
    }
}

.rotate_object {
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
}

.rotate_animation {
    transform: rotate(180deg);
    transform-origin: center;
}
