.home_images {
    height: 100vh;
    max-width: 100vw;
    background-image: url("../static/background.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
}

.home_text {
    font-size: 1.7rem;
}

@media only screen and (min-width: 768px) {
    .home_text {
        font-size: 2.5rem;
    }
}
