.App {
    text-align: center;
    max-width: 100vw;
    overflow-x: hidden;
}

/* Disable outline when click to object */
.btn,
.form-select,
.form-select:active,
.form-select:focus,
.form-select:focus:active,
.form-control,
.form-control:active,
.form-control:focus,
.form-control:focus:active {
    outline: 0px !important;
    -webkit-appearance: none;
    box-shadow: none !important;
}

.btn:hover {
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2) !important;
}