.main {
    padding-top: 56px;
    padding-left: 2.25rem;
    padding-right: 2.25rem;
    color: var(--text) !important;
}

@media only screen and (min-width: 768px) {
    .main {
        padding-left: 8.688rem;
        padding-right: 8.688rem;
    }
}

@media only screen and (min-width: 1367px) {
    .main {
        padding-left: calc(8.688rem + 12.5%);
        padding-right: calc(8.688rem + 12.5%);
    }
}
